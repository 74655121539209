<template>
  <div>
    <banner-help />
    <content-help />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import bannerHelp from './bannerHelp.vue'
import contentHelp from './contentHelp.vue'

export default {
  components: {
    BRow,
    BCol,
    bannerHelp,
    contentHelp
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    fetchData() {
      this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => { this.faqData = res.data })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-help.scss';
</style>
