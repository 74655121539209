<template>
    <div class="help-content">
        <b-col
            offset-lg="2"
            lg="8"
        >
            <h2>Frequently Asked Questions</h2>
            <p>We are ready to answer all your questions about cost and performance management</p>
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >
                <app-collapse-item
                    title="Does my subscription automatically renew?"
                >
                    Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chips pudding.
                </app-collapse-item>
            </app-collapse>
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >
                <app-collapse-item
                    title="Can I store the item on an intranet so everyone has access?"
                >
                    Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chips pudding.
                </app-collapse-item>
            </app-collapse>
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >
                <app-collapse-item
                    title="What does non-exclusive mean?"
                >
                    Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chips pudding.
                </app-collapse-item>
            </app-collapse>
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >
                <app-collapse-item
                    title="Is the Regular License the same thing as an editorial license?"
                >
                    Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chips pudding.
                </app-collapse-item>
            </app-collapse>
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >
                <app-collapse-item
                    title="Which license do I need for an end product that is only accessible to paying users?"
                >
                    Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chips pudding.
                </app-collapse-item>
            </app-collapse>
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >
                <app-collapse-item
                    title="Which license do I need to use an item in a commercial?"
                >
                    Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chips pudding.
                </app-collapse-item>
            </app-collapse>
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >
                <app-collapse-item
                    title="Can I re-distribute an item? What about under an Extended License?"
                >
                    Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chips pudding.
                </app-collapse-item>
            </app-collapse>
        </b-col>
    </div>
</template>

<script>
import { BRow, BCol, } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        BRow,
        BCol,
        AppCollapse,
        AppCollapseItem
    },
}
</script>